/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@1.12.4/dist/jquery.min.js
 * - /npm/bootstrap@3.4.1/dist/js/bootstrap.min.js
 * - /npm/owl-carousel@1.0.0/owl-carousel/owl.carousel.min.js
 * - /npm/jquery.maskedinput@1.4.1/src/jquery.maskedinput.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
